<template>
  <span :class="dense ? 'dense' : ''">
    {{ from && $utils.format(from) }}
    <div v-if="vertical" class="divid"></div>
    <v-icon :class="dense ? 'mr-1 ml-1' : 'mr-3 ml-3'" small
      >mdi-{{ icon_name }}</v-icon
    >
    <div v-if="vertical" class="divid"></div>
    {{ to && $utils.format(to) }}
  </span>
</template>

<script>
/*
@desc Displays a time range (width center arrow)
*/
export default {
  /*
    @prop1 Date
    @prop2 Date
    @prop3 Boolean
    @prop4 Boolean
    */
  props: ["from", "to", "dense", "vertical"],
  computed: {
    /*
        @desc Determines if icon is arrow down or left (vertical or not)
        */
    icon_name() {
      return this.vertical ? "arrow-down" : "arrow-right";
    },
  },
  mounted() {},
};
</script>

<style scoped>
.dense .divid {
  margin-top: -5px;
}
</style>
