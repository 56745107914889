<template>
  <span>
    <v-expansion-panels>
      <!-- ------------------------------------------------ ORGANIZATION --------- -->
      <v-expansion-panel v-for="orga in organizations" :key="orga.id">
        <v-expansion-panel-header>
          <orga-card
            :orga="orga"
            :elevation="0"
            :dense="$is_mobile"
            :edit_orga="edit_orga && edit_orga.id == orga.id ? edit_orga : null"
            @update="update_orga(orga)"
            @cancel="edit_orga = {}"
            :sending_update="sending_update"
          ></orga-card>
        </v-expansion-panel-header>
        <!-- ------------------ content --------- -->
        <v-expansion-panel-content>
          <v-btn
            v-if="edit_orga.id != orga.id"
            color="primary"
            @click="set_edit_orga(orga)"
          >
            <v-icon class="mr-3">mdi-pencil</v-icon>{{ $t("admin_orgas.edit") }}
          </v-btn>
          <v-card-title>{{ $t("admin_orgas.places") }}</v-card-title>
          <!-- ------------------ "no item to display" text --------- -->
          <v-card-title
            v-if="Object.keys(orga.areas).length == 0"
            class="ml-5 mt-0"
            style="opacity: 0.5"
            >{{ $t("admin_orgas.noplace") }}</v-card-title
          >
          <v-expansion-panels>
            <!-- ------------------------------------------------ AREAS --------- -->
            <v-expansion-panel v-for="area in orga.areas" :key="area.id">
              <v-expansion-panel-header>
                <v-icon class="mr-5">mdi-map-marker</v-icon>
                <v-card-text class="pl-0"
                  ><b>{{ area.label }}</b> - {{ area.address }}</v-card-text
                >
                <v-switch
                  v-model="area.activated"
                  @click.stop="set_area_state(area)"
                  style="margin-right: 20px"
                ></v-switch>
              </v-expansion-panel-header>
              <!-- ------------------ content --------- -->
              <v-expansion-panel-content>
                <v-card-title>{{ $t("admin_orgas.products") }}</v-card-title>
                <v-expansion-panels color="primary">
                  <!-- ------------------------------------------------ PRODUCTS --------- -->
                  <v-expansion-panel
                    v-for="product in area.products"
                    :key="product.id"
                  >
                    <v-expansion-panel-header>
                      <v-icon class="mr-5">mdi-tag</v-icon>
                      <v-card-text class="pl-0">{{
                        product.label
                      }}</v-card-text>

                      <v-switch
                        color="secondary"
                        v-model="product.activated"
                        @click.stop="set_product_state_state(product)"
                      ></v-switch>
                    </v-expansion-panel-header>
                    <!-- ------------------ content --------- -->
                    <v-expansion-panel-content
                      v-if="product_indidpos[product.id]"
                    >
                      <v-row>
                        <v-col>
                          <product :product="product"></product>
                        </v-col>
                      </v-row>
                      <v-card-title>
                        {{ $t("admin_orgas.unavailability") }}
                        <v-btn
                          icon
                          color="primary"
                          @click="prepare_indispo(product.id)"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-list>
                        <v-card
                          v-for="indispo in product_indidpos[product.id]"
                          :key="indispo.id"
                          class="mb-3 mt-0"
                          color="error"
                          dark
                        >
                          <v-card-text>
                            <v-btn
                              icon
                              right
                              @click="remove_indispo(product.id, indispo.id)"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <from-to
                              :from="indispo.startDate"
                              :to="indispo.endDate"
                            ></from-to>
                          </v-card-text>
                        </v-card>
                      </v-list>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- ------------------------------------------------ RESERVATIONS --------- -->
          <v-card-title>{{ $t("admin_orgas.bookings") }}</v-card-title>
          <!-- ------------------ filter --------- -->
          <v-text-field
            :label="$t('admin_orgas.search')"
            :placeholder="$t('admin_orgas.whatsearch')"
            outlined
            class="mx-3"
            v-model="resa_filter_map[orga.id]"
            v-if="Object.values(orga.reservations).length > 0"
          ></v-text-field>
          <!-- ------------------ "no item to display" text --------- -->
          <v-card-title
            v-if="filtered_reservations(orga).length == 0"
            class="ml-5 mt-0"
            style="opacity: 0.5"
          >
            <template v-if="Object.values(orga.reservations).length == 0">{{
              $t("admin_orgas.nobooking")
            }}</template>
            <template v-else-if="!resa_filter_map[orga.id]">{{
              $t("admin_orgas.pleasesearch")
            }}</template>
            <template v-else
              >{{ $t("admin_orgas.nobookingfor") }} "{{
                resa_filter_map[orga.id]
              }}"</template
            >
          </v-card-title>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="resa in $utils.sort_by_date(
                filtered_reservations(orga),
                'startDate',
                true
              )"
              :key="resa.id"
            >
              <!-- ------------------ header --------- -->
              <v-expansion-panel-header>
                <v-icon
                  class="mr-5"
                  :disabled="
                    resa_passed(resa) ||
                    resa.status == 'refunded' ||
                    resa.status == 'canceled'
                  "
                >
                  mdi-{{
                    resa.status == "refunded" || resa.status == "canceled"
                      ? "close"
                      : resa_passed(resa)
                      ? "clock-check"
                      : "ticket-confirmation"
                  }}
                </v-icon>
                <v-card-text class="pl-1"
                  ><b>@{{ resa.user.login }}</b
                  >&nbsp;-&nbsp;
                  <from-to
                    :from="resa.startDate"
                    :to="resa.endDate"
                    :dense="true"
                    class="mb-3"
                  ></from-to
                  >&nbsp;- <b>{{ resa.product.area.label }}</b
                  >, {{ resa.product.label }}
                  <v-card-subtitle
                    v-if="resa.status == 'refunded'"
                    color="error"
                    class="pa-0"
                    >{{ $t("refunded") }}</v-card-subtitle
                  >
                  <v-card-subtitle
                    v-if="resa.status == 'canceled'"
                    color="error"
                    class="pa-0"
                    >{{ $t("admin_orgas.canceled") }}</v-card-subtitle
                  >
                  <v-card-subtitle
                    v-else-if="resa_passed(resa)"
                    color="error"
                    class="pa-0"
                    >{{ $t("admin_orgas.past") }}</v-card-subtitle
                  >
                </v-card-text>
              </v-expansion-panel-header>
              <!-- ------------------ content --------- -->
              <v-expansion-panel-content>
                <v-card-text>
                  <user-card
                    :user="resa.user"
                    :dense="true"
                    :href="'mailto:' + resa.user.email"
                  ></user-card>
                  <product :product="resa.product" :elevation="3"></product>
                  <v-btn
                    class="mt-5"
                    text
                    v-if="
                      resa.status != 'refunded' &&
                      resa.status != 'canceled' &&
                      !resa_passed(resa) &&
                      !is_started(resa)
                    "
                    @click="prepare_delete_item(orga.id, resa)"
                    color="error"
                    :disabled="deleteing_item_id == resa.id"
                    :loading="deleteing_item_id == resa.id"
                    >{{ $t("admin_orgas.cancelbooking") }}</v-btn
                  >
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- ----------------------------------------------------------------------- CANCEL RESA DIALOG -->
    <acting-dialog
      :cancel_text="$t('admin_orgas.canceltext')"
      :ok_text="$t('admin_orgas.cancelbooking')"
      :object="resa_to_remove"
      @ok="delete_item"
    >
      <v-card-title v-if="resa_to_remove"
        >{{ $t("admin_orgas.cancelbooking") }}
        {{ resa_to_remove.id }}</v-card-title
      >
    </acting-dialog>
    <!-- ----------------------------------------------------------------------- INDISPO DIALOG -->
    <v-dialog v-model="product_need_indispo" max-width="500">
      <v-card class="pa-5">
        <v-card-title class="mb-5">{{
          $t("admin_orgas.createunavailability")
        }}</v-card-title>

        <time-filter
          v-model="indispos"
          :label="$t('admin_orgas.unavailabilitydates')"
          mode="quarters"
        ></time-filter>

        <!-- <time-selector
                    v-model="indispo_start"
                    label="début d'indispo"
                ></time-selector>
                <time-selector
                    v-model="indispo_end"
                    :start_date="new Date(indispo_start)"
                    label="fin d'indispo"
                ></time-selector> -->

        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="
              product_need_indispo = false;
              product_to_set_indispo = null;
              indispos[0] = null;
              indispos[1] = null;
            "
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn color="primary" large @click="create_indispo">{{
            $t("admin_orgas.create")
          }}</v-btn>
        </v-card-title>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import product from "../components/product.vue";
import fromTo from "../components/from-to.vue";
import userCard from "../components/user-card.vue";
import timeSelector from "../components/time-selector.vue";
import actingDialog from "../components/acting-dialog.vue";
import orgaCard from "../components/orga-card.vue";
import TimeFilter from "./time-filter.vue";
/*
@desc Admin reserved data managment panel
*/
export default {
  components: {
    product,
    fromTo,
    userCard,
    timeSelector,
    actingDialog,
    orgaCard,
    TimeFilter,
  },
  /*
    @desc data
    */
  data: () => ({
    organizations: {},
    product_indidpos: {},
    edit_orga: {},
    sending_update: false,

    product_to_set_indispo: null,
    product_need_indispo: false,

    indispos: [null, null],

    // indispo_start: null,
    // indispo_end: null,

    resa_filter_map: {},

    deleteing_item_id: null,
    resa_to_remove: null,
  }),
  watch: {
    organizations: {
      handler() {},
      deep: true,
    },
  },
  methods: {
    /*
        @desc Change Area state (activate / deactivate)
        */
    set_area_state(area) {
      const api = area.activated ? "activate" : "deactivate";
      this.$api.manager.areas[api](area.id);
    },
    /*
        @desc Change Product state (activate / deactivate)
        */
    set_product_state_state(product) {
      const api = product.activated ? "activate" : "deactivate";
      this.$api.manager.products.activation[api](product.id);
    },
    /*
        @desc Change current editing organization (addresse & phone)
        */
    set_edit_orga(orga) {
      this.edit_orga = JSON.parse(JSON.stringify(orga));
    },
    is_started(resa) {
      const now = Date.now();
      const resa_start = new Date(resa.startDate).getTime();
      return now >= resa_start;
    },
    async update_orga() {
      const { address, phoneNumber, mailAddress } = this.edit_orga;
      const orga = { address, phoneNumber, mailAddress };
      this.sending_update = true;
      await this.$api.manager.orgas.update(this.edit_orga.id, orga);
      this.sending_update = false;
      this.edit_orga = {};
      this.load_orgas();
    },
    async load_orgas() {
      this.organizations = await this.$api.manager.orgas.mine();
      Object.values(this.organizations).forEach((o) =>
        this.resa_filter_map[o.id]
          ? null
          : this.$set(this.resa_filter_map, o.id, "")
      );
      const products = Object.values(this.organizations)
        .map((o) =>
          Object.values(o.areas).map((a) => Object.values(a.products))
        )
        .flat(5)
        .map((p) => p.id);
      products.forEach(async (pid) => {
        const indispos = await this.$api.manager.products.indispos.get_all(pid);
        this.$set(
          this.product_indidpos,
          pid,
          Object.fromEntries(
            indispos
              .filter(({ status }) => status == "reserved")
              .map(({ id, startDate, endDate }) => ({ id, startDate, endDate }))
              .map((indispo) => [indispo.id, indispo])
          )
        );
      });
    },
    resa_passed(resa) {
      const end_date = resa.endDate;
      return this.$utils.time_is_in_past(end_date);
    },
    resa_to_text(resa) {
      return [
        "*",
        // -------------------- user
        resa.user.firstName,
        resa.user.lastName,
        resa.user.customerId,
        resa.user.email,
        resa.user.login,
        "@" + resa.user.login,
        // -------------------- resa data
        resa.status,
        {
          paid: this.$t("admin_orgas.resapaid"),
          billed: this.$t("admin_orgas.resabilled"),
          refunded: this.$t("admin_orgas.resacanceledrefunded"),
          canceled: this.$t("admin_orgas.canceled"),
        }[resa.status],
        resa.code,
        resa.id,
        resa.price / 100,
        this.$utils.format(resa.startDate),
        this.$utils.format(resa.endDate),
        this.resa_passed(resa)
          ? this.$t("admin_orgas.past")
          : this.$t("admin_orgas.resapending"),
        // -------------------- product
        resa.product.label,
        resa.product.id,
        resa.product.type.label,
      ]
        .filter((e) => e)
        .map((e) => String(e).toLowerCase().replace(/ /g, "_"))
        .join("_");
    },
    filtered_reservations(orga) {
      const orga_id = orga.id;
      const resas = Object.values(orga.reservations);
      const filter = this.resa_filter_map[orga_id].toLowerCase();
      const filters = filter
        .replace(/"(.*?)"/g, (_, g) => g.replace(/ /g, "_"))
        .replace(/,/g, ", ")
        .replace(/  /g, " ")
        .split(" ")
        .filter((e) => e);
      if (!filters.length) return [];
      return resas.filter((resa) => {
        const resa_str = this.resa_to_text(resa);
        return (
          filters.filter((f) => resa_str.includes(f)).length == filters.length
        );
      });
    },
    /*
        @desc Display the "create indispo" dialog
        */
    prepare_indispo(product_id) {
      this.product_need_indispo = true;
      this.product_to_set_indispo = product_id;
    },
    /*
        @desc Send indispo data (in indispo creation dialog) for indispo creation
        */
    async create_indispo() {
      this.product_need_indispo = false;
      const product_id = this.product_to_set_indispo;
      const from = this.indispos[0].getTime() / 1000;
      const to = this.indispos[1].getTime() / 1000;
      const indispo = await this.$api.manager.products.indispos.create(
        product_id,
        { from, to }
      );
      this.$set(this.product_indidpos[product_id], indispo.id, indispo);
    },
    remove_indispo(product_id, indispo_id) {
      if (!confirm(this.$t("admin_orgas.deleteunavailability"))) return;
      this.$api.manager.products.indispos.delete(indispo_id);
      this.$delete(this.product_indidpos[product_id], indispo_id);
    },
    /*
        @desc Cancel Item
        */
    async delete_item(item) {
      this.resa_to_remove = null;
      const item_id = item.id;
      const orga_id = item.orga_id;
      this.deleteing_item_id = item_id;
      await this.$api.manager.resas.delete(item_id);
      this.deleteing_item_id = null;
      this.organizations[orga_id].reservations[item_id].status =
        item.price == 0 ? "canceled" : "refunded";
    },
    prepare_delete_item(orga_id, item) {
      item.orga_id = orga_id;
      this.resa_to_remove = null;
      setTimeout(() => (this.resa_to_remove = item));
    },
  },
  /*
    @desc Auto Load all organizations
    */
  mounted() {
    this.load_orgas();
  },
};
</script>

<style></style>
