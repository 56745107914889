<template>
  <v-dialog
    v-model="isDatePickerVisible"
    max-width="700px"
    :fullscreen="$is_mobile"
    transition="dialog-bottom-transition"
    v-if="selectedDays.length > 0"
  >
    <!-- ----------------------------------------------------- TEXT FIELD -->
    <template v-slot:activator="{ on }">
      <v-text-field
        :label="label"
        prepend-inner-icon="mdi-calendar"
        outlined
        readonly
        v-on="on"
        :value="formattedDateRange"
      ></v-text-field>
    </template>
    <!-- ----------------------------------------------------- DIALOG -->

    <v-card class="pa-5 text-center">
      <v-card-title class="justify-center mb-5">
        <from-to
          :from="datesWithHours[0]"
          :to="datesWithHours[1]"
          :vertical="$is_mobile"
          :dense="$is_mobile"
        ></from-to>
      </v-card-title>

      <!-- ----------------------------- date picker -->
      <v-date-picker
        :no-title="$is_mobile"
        class="dater"
        v-model="selectedDays"
        range
        color="primary"
        :locale="$i18n.locale"
        first-day-of-week="1"
        elevation="5"
        :allowed-dates="allowedDates"
      ></v-date-picker>
      <!-- ----------------------------- timer sliders -->
      <div :class="['time_slider text-left mt-7', $is_mobile ? 'mx-5' : '']">
        <!-- ------------------------- multi date -->
        <v-row class="ma-0">
          <v-col
            class="ma-0 pa-0"
            :cols="$is_mobile ? 12 : 5"
            style="display: flex"
          >
            <v-spacer></v-spacer>
            <time-selecter
              v-model="selectedDayTimes[0]"
              :title="formattedSelectedDays[0]"
              :times="availableFirstDateTimes"
              :disabled="this.mode === 'mids'"
            >
            </time-selecter>
            <v-spacer v-if="$is_mobile"></v-spacer>
          </v-col>
          <v-col :cols="$is_mobile ? 12 : 2" style="display: flex">
            <v-spacer></v-spacer>
            <v-icon class="mr-3 ml-3" small
              >mdi-arrow-{{ $is_mobile ? "down" : "right" }}</v-icon
            >
            <v-spacer></v-spacer>
          </v-col>
          <v-col
            class="ma-0 pa-0"
            :cols="$is_mobile ? 12 : 5"
            style="display: flex"
          >
            <v-spacer v-if="$is_mobile"></v-spacer>
            <time-selecter
              v-if="formattedSelectedDays[1]"
              v-model="selectedDayTimes[1]"
              :title="formattedSelectedDays[1]"
              :times="availableLastDateTimes"
              :disabled="this.mode === 'mids'"
            >
            </time-selecter>
            <v-spacer></v-spacer>
          </v-col>
        </v-row>
      </div>
      <v-card-actions class="mt-10">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="cancel">{{ $t("cancel") }}</v-btn>
        <v-btn color="primary" @click="validate" :disabled="!canConfirm">{{
          $t("validate")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format, parse } from "date-fns";
import FromTo from "./from-to.vue";
import TimeSelecter from "./time-selecter.vue";
/*
@desc Date Time selection system featuring 4 time modes:
mids (opening/closing), jumps (special hours), hours (every hours), quarters (every 15 minutes)
*/
export default {
  props: ["value", "label", "mode", "maximumDate"],
  components: { FromTo, TimeSelecter },
  data: () => ({
    isDatePickerVisible: false,

    selectedDays: [], // [YYYY-MM-DD, YYYY-MM-DD]
    selectedDayTimes: [], // [[HH, MM], [HH, MM]]

    openingHour: 0,
    closingHour: 23,

    minReservationTimeHours: 1,
    maxReservationTimeHours: 10000,
  }),
  computed: {
    firstDateWithTime() {
      if (!this.selectedDays[0]) return;

      const firstDate = new Date(this.selectedDays[0]);
      firstDate.setHours(...this.selectedDayTimes[0]);
      return firstDate;
    },

    lastDateWithTime() {
      if (!this.selectedDays[1]) return;

      const lastDate = new Date(this.selectedDays[1]);
      lastDate.setHours(...this.selectedDayTimes[1]);
      return lastDate;
    },

    minDate() {
      if (this.firstDateWithTime && !this.lastDateWithTime) {
        return this.firstDateWithTime;
      }

      const now = new Date();
      let minDate = now;

      if (this.lastDateWithTime && !this.firstDateWithTime) {
        // Further date from lastDateWithTime
        const furtherDate = new Date(this.lastDateWithTime);
        furtherDate.setHours(
          furtherDate.getHours() - this.maxReservationTimeHours
        );
        if (furtherDate > now) minDate = furtherDate;
      }

      // Ensure returned date respects opening hours
      const currentHour = minDate.getHours();
      if (currentHour >= this.closingHour) {
        minDate.setDate(minDate.getDate() + 1);
      }

      if (currentHour >= this.closingHour || currentHour < this.openingHour) {
        minDate.setHours(this.openingHour, 0, 0, 0);
      }

      return minDate;
    },

    maxDate() {
      if (this.lastDateWithTime && !this.firstDateWithTime) {
        return this.lastDateWithTime;
      }

      const now = new Date();
      let maxDate = now;
      maxDate.setDate(maxDate.getDate() + this.maximumDate);
      maxDate.setHours(this.closingHour, 0, 0, 0);

      if (this.firstDateWithTime && !this.lastDateWithTime) {
        // Further date from firstDateWithTime
        const furtherDate = new Date(this.firstDateWithTime);
        furtherDate.setHours(
          furtherDate.getHours() + this.maxReservationTimeHours
        );
        if (furtherDate < maxDate) maxDate = furtherDate;
      }

      // Ensure returned date respects opening hours
      const currentHour = maxDate.getHours();
      if (currentHour < this.openingHour) {
        maxDate.setDate(maxDate.getDate() - 1);
      }

      if (currentHour >= this.closingHour || currentHour < this.openingHour) {
        maxDate.setHours(this.closingHour, 0, 0, 0);
      }

      return maxDate;
    },

    allowedDates() {
      return (val) => {
        const date = new Date(val);
        // Set time to noon to avoid timezone issues when comparing dates
        date.setHours(12, 0, 0, 0);

        const min = new Date(this.minDate);
        const max = new Date(this.maxDate);

        // Check if date is within allowed range
        return date >= min && date <= max;
      };
    },

    availableFirstDateTimes() {
      return this.getAvailableTimes(this.selectedDays[0]);
    },

    availableLastDateTimes() {
      return this.getAvailableTimes(this.selectedDays[1]);
    },

    datesWithHours() {
      return this.selectedDays.map((d, i) => {
        const date = new Date(d);

        const selectedDayTimes = this.selectedDayTimes[i];
        date.setHours(...selectedDayTimes);

        return date;
      });
    },
    formattedSelectedDays() {
      return this.selectedDays.map((d) =>
        format(this.$utils.check_date(d), "dd/MM/yyyy")
      );
    },
    formattedDateRange() {
      if (!this.datesWithHours[1]) {
        return this.$utils.format(this.datesWithHours[0]);
      }

      return `${this.$utils.format(this.datesWithHours[0])} ${this.$t(
        "time-filter.and"
      )} ${this.$utils.format(this.datesWithHours[1])}`;
    },
    canConfirm() {
      return (
        this.selectedDays.length === 2 &&
        this.selectedDays[0] &&
        this.selectedDays[1]
      );
    },
  },
  methods: {
    getAvailableTimes(selectedDay) {
      let times = [];

      // Fill with hours based on mode
      if (this.mode === "mids") {
        return [
          [this.openingHour, 0],
          [this.closingHour, 0],
        ];
      } else if (this.mode === "quarters") {
        for (let i = this.openingHour; i < this.closingHour; i++) {
          times.push([i, 0], [i, 15], [i, 30], [i, 45]);
        }
        times.push([this.closingHour, 0]);
      } else if (this.mode === "jumps") {
        times = [this.openingHour, 12, 13, this.closingHour].map((h) => [
          h % 24,
          0,
        ]);
      } else {
        // Default hourly mode
        for (let i = this.openingHour; i <= this.closingHour; i++) {
          times.push([i, 0]);
        }
      }

      // Filter out hours outside of minDate and maxDate
      return times.filter((time) => {
        const date = new Date(selectedDay);
        date.setHours(...time);
        return date >= this.minDate && date <= this.maxDate;
      });
    },
    cancel() {
      this.isDatePickerVisible = false;
      this.updateDatesFromValue();
    },
    validate() {
      this.isDatePickerVisible = false;
      this.$emit("input", this.datesWithHours);
    },
    updateDatesFromValue() {
      if (this.value[0]) {
        const d1 = format(this.value[0], "yyyy-MM-dd");
        const d2 = format(this.value[1], "yyyy-MM-dd");

        const t1 = format(this.value[0], "HH:mm")
          .split(":")
          .map((e) => parseInt(e));
        const t2 = format(this.value[1], "HH:mm")
          .split(":")
          .map((e) => parseInt(e));

        this.selectedDays = [d1, d2];
        this.selectedDayTimes = [t1, t2];
      } else {
        const minDatePlusOneDay = new Date(this.minDate);
        minDatePlusOneDay.setDate(minDatePlusOneDay.getDate() + 1);
        const d1 = format(minDatePlusOneDay, "yyyy-MM-dd");

        this.selectedDays = [d1, d1];

        this.selectedDayTimes = [
          [this.openingHour, 0],
          [this.closingHour, 0],
        ];
      }
    },
  },

  mounted() {
    this.updateDatesFromValue();
  },
};
</script>

<style>
.dater {
  margin-left: auto;
  margin-right: auto;
}
</style>
