<template>
  <div></div>
</template>

<script>
export default {
  name: "ThemeLoader",
  async created() {
    try {
      const orgas = await this.$api.user.organizations.get_all();
      if (orgas && orgas[0] && orgas[0].colors) {
        const colors = JSON.parse(orgas[0].colors);
        this.$vuetify.theme.themes.dark = {
          ...this.$vuetify.theme.themes.dark,
          ...colors,
        };
      }
    } catch (err) {
      console.error("Error loading organization theme:", err);
    }
  },
};
</script>
