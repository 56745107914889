import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Cart from "../views/Cart.vue";
import Connect from "../views/Connect.vue";
import Account from "../views/Account.vue";
import CGU from "../views/CGU.vue";
import PassReset from "../views/PassReset.vue";
import Order from "../views/Order.vue";
import Orders from "../views/Orders_reordered.vue";
import Contacts from "../views/Contacts.vue";
import Reservation from "../views/Reservation.vue";
import Activate from "../views/Activate.vue";
import Tutorial from "@/views/Tutorial.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/cart",
    name: "cart",
    component: Cart,
  },
  {
    path: "/connect",
    name: "connect",
    component: Connect,
  },
  {
    path: "/account",
    name: "account",
    component: Account,
  },
  {
    path: "/cgu",
    name: "cgu",
    component: CGU,
  },
  {
    path: "/password_reset",
    name: "reset_password",
    component: PassReset,
  },
  {
    path: "/order",
    name: "order_result",
    component: Order,
  },
  {
    path: "/orders",
    name: "orders",
    component: Orders,
  },
  {
    path: "/contacts",
    name: "contacts",
    component: Contacts,
  },
  {
    path: "/Reservation",
    name: "reservation",
    component: Reservation,
  },
  {
    path: "/Activate",
    name: "activate",
    component: Activate,
  },
  {
    path: "/tutorial",
    name: "tutorial",
    component: Tutorial,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
