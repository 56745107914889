<template>
    <div>
        <v-container
            class='resa_container'
            v-if="item"
        >

            <h2>{{$t('reservation.booking')}}</h2>
            <div
                v-if="$is_mobile"
                class='text-center ma-5'
            >
                <from-to
                    :from="item.startDate"
                    :to="item.endDate"
                    style="color:#666;font-size:15px;width:100%"
                    :vertical="true"
                >
                </from-to>
            </div>
            <template v-else>
                <v-card-title
                    class='pa-0 pb-5'
                    style="font-weight:normal;color:#666"
                >
                    {{$t('reservation.from')}}&nbsp;<b class='secondary--accent'>{{times.from}}</b>&nbsp;{{$t('reservation.to')}}&nbsp;<b>{{times.to}}</b>
                    <v-spacer></v-spacer>
                    {{times.spent}}
                </v-card-title>
            </template>

            <v-row class='mb-3'>
                <v-col>
                    <product
                        :product="item.product"
                        :dense="show_code && $is_mobile"
                        :closable="true"
                        close-icon="qrcode"
                        close-icon-color='primary'
                        @close="show_code = !show_code"
                        elevation="5"
                    ></product>
                </v-col>
                <v-col
                    v-if="show_code && !$is_mobile"
                    cols="3"
                >
                    <v-img
                        :src="qrcode_src"
                        contain
                    />
                </v-col>
            </v-row>
            <v-img
                v-if="show_code && $is_mobile"
                :src="qrcode_src"
                contain
                style="max-width: 120px !important; margin: auto !important; margin-bottom: 20px !important;"
            />

            <v-card-subtitle class='pt-0 resa_code'>
                <span>{{$t('reservation.accesscode')}}</span><br />
                {{item.code}}
            </v-card-subtitle>

            <v-btn
                :to="'/orders?order_id='+item.orderId"
                text
                color="primary"
                class='mt-5 ml-0 pl-0'
            >
                <v-icon class='mr-3 ml-0'>mdi-arrow-left</v-icon>{{$t('reservation.backtobookings')}}
            </v-btn>
        </v-container>

        <v-dialog
            v-model="need_connect"
            max-width="400"
        >
            <v-card class='pa-5'>
                <v-btn
                    icon
                    absolute
                    top
                    right
                    @click="need_to_connect=false;$router.push('/')"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <connector @connected="need_connect=false"></connector>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import Product from '../components/product.vue'
import connector from '../components/connector.vue'
import FromTo from '../components/from-to.vue'
/*
@desc One order (item) vizualisation page
*/
export default {
    components: { Product, connector, FromTo },
    data: () => ({
        item: null,
        code_type: 'q',
        show_code: false,
        types: [
            { code: 'q', name: 'QR code' },
            { code: 'b', name: 'code barre' },
            { code: 'p', name: 'FedEx' },
        ],
        need_connect: false,
    }),
    watch: {
        /*
        @desc Reload item UI if "is now connected"
        */
        need_connect() {
            this.reload()
        }
    },
    computed: {
        /*
        @desc Computed backend qrcode url (using comp data qrcode token)
        */
        qrcode_src() {
            return process.env.VUE_APP_API_HOST + `/c/${this.code_type}/${this.item.token}`
        },
        /*
        @desc Item time range
        */
        times() {
            return {
                from: this.$utils.format(this.item.startDate),
                to: this.$utils.format(this.item.endDate),
                spent: this.$utils.spend(this.item.startDate, this.item.endDate)
            }
        }
    },
    methods: {
        /*
        @desc Reload Item
        */
        async reload() {
            const id = this.$utils.get_url_args().item_id
            if (!id) this.$router.push('/orders')
            try {
                const item = await this.$api.user.booking.item(id).then(item => this.item = item)
                console.log(item)
                this.item = item
            } catch (e) {
                this.need_connect = true
            }
        }
    },
    /*
    @desc Auto load Item + check if qrcode need to be shown (from url args)
    */
    mounted() {
        this.show_code = this.$utils.get_url_args().qrcode == 'true'
        this.reload()
    }
}
</script>

<style>
.resa_container {
    max-width: 100%;
    width: 800px !important;
}
.resa_code {
    max-width: calc(100% - 10px);
    word-break: break-all;
    opacity: 0.8;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 5px;
    line-height: 30px;
}

.resa_code span {
    opacity: 0.7;
    font-weight: normal;
    letter-spacing: normal;
}
</style>