<template>
  <div style="display: flex; align-items: center">
    {{ title }}
    <v-select
      class="ml-5"
      :items="items"
      v-model="selectedTime"
      style="max-width: 80px"
      :disabled="disabled"
    >
    </v-select>
  </div>
</template>

<script>
export default {
  props: ["value", "title", "times", "disabled"],
  data: () => ({
    selectedTime: "",
  }),
  watch: {
    selectedTime() {
      this.$emit("input", this.splittedSelectedTime);
    },
  },
  computed: {
    splittedSelectedTime() {
      return this.selectedTime.split(":").map((e) => parseInt(e));
    },
    items() {
      return this.times.map((time, i) => {
        return time[0] + ":" + this.$utils.add_zeros(time[1]);
      });
    },
  },
  mounted() {
    if (!this.value) {
      this.selectedTime =
        this.times[0][0] + ":" + this.$utils.add_zeros(this.times[0][1]);
    } else {
      this.selectedTime =
        this.value[0] + ":" + this.$utils.add_zeros(this.value[1]);
    }
  },
};
</script>

<style></style>
